import { TariffCoaMappingConstant, tariffCoaMappingRequiredFieldList } from "presentation/constant/TariffCoaMapping/TariffCoaMappingConstant";
import { useTariffCoaMappingVM } from "presentation/hook/TariffCoaMapping/useTariffCoaMappingVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useTariffCoaMappingTracked } from "presentation/store/TariffCoaMapping/TariffCoaMappingProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { memo, useCallback, useMemo, useState } from "react";
import { FieldType, HPHBreadcrumb, HPHCheckbox, HPHGroupTitle, IconButton, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, SidebarCaption, Sidebarheader, SidebarTitle, StyledAction, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const TariffCoaMappingEditPanel = () => {
    const TARIFF_COA_MAPPING_CONSTANT = TariffCoaMappingConstant.Table;
    const [isLoading, setIsLoading] = useState(false);
    const [tariffCoaMappingState] = useTariffCoaMappingTracked();
    const tariffCoaMappingVM = useTariffCoaMappingVM();
    const { currentEditRow, isAdd, isEdit, tariffCoaMappingList } = tariffCoaMappingState;
    const messageBarVM = useMessageBarVM();


    const handleSave = useCallback(async () => {
        if (!currentEditRow.tariffType) {
            messageBarVM.showError("Tariff Type is mandatory.");
            return;
        }
        if (!currentEditRow.tariffCode) {
            messageBarVM.showError("Tariff Code is mandatory.");
            return;
        }
        if (isAdd && tariffCoaMappingList.find(entity => entity.tariffType === currentEditRow.tariffType && entity.tariffCode === currentEditRow.tariffCode)) {
            messageBarVM.showError("Duplicate record found, please check the key: Tariff Type, Tariff Code.");
            return;
        }
        if (!currentEditRow.raType) {
            messageBarVM.showError("RA Type is mandatory.");
            return;
        }
        if (!currentEditRow.revenueAccountCode) {
            messageBarVM.showError("Revenue A/C Code is mandatory.");
            return;
        }
        if (!currentEditRow.costCenterCode) {
            messageBarVM.showError("Cost Center Code is mandatory.");
            return;
        }
        if (!currentEditRow.productServiceCode) {
            messageBarVM.showError("Product Service Code is mandatory.");
            return;
        }
        if (!currentEditRow.salesChannelCode) {
            messageBarVM.showError("Sales Channel Code is mandatory.");
            return;
        }
        if (!currentEditRow.projectCode) {
            messageBarVM.showError("Project Code is mandatory.");
            return;
        }
        if (!currentEditRow.spareCode) {
            messageBarVM.showError("Spare Code is mandatory.");
            return;
        }
        if (!currentEditRow.activeInd) {
            messageBarVM.showError("Active Ind is mandatory.");
            return;
        }
        setIsLoading(true);
        const data = await tariffCoaMappingVM.onSave(currentEditRow);
        if (data && data.success) {
            messageBarVM.showSuccess("Save Successfully.");
            tariffCoaMappingVM.searchAllTariffCoaMappingList().then((data) => {
                setIsLoading(false)
            }).catch(error => {
                setIsLoading(false)
            });
        } else {
            setIsLoading(false)
            messageBarVM.showError(data.data);
            return;
        }
    }, [currentEditRow, isAdd, tariffCoaMappingList, tariffCoaMappingVM, messageBarVM]);

    const handClose = useCallback(() => {
        tariffCoaMappingVM.onCloseEidtPanel();
    }, [tariffCoaMappingVM]);

    const memoTariffCodeOptions = useMemo(() => {
        return currentEditRow.tariffType
            ? tariffCoaMappingState.dynamicOptions.tariffCodeDropdownOptions[currentEditRow.tariffType]
            : [];
    }, [currentEditRow.tariffType, tariffCoaMappingState.dynamicOptions.tariffCodeDropdownOptions]);

    const memoTariffType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={false}
                readOnlyValue={currentEditRow?.tariffType || ''}
                fieldValue={currentEditRow?.tariffType}
                fieldLabel={TARIFF_COA_MAPPING_CONSTANT.TARIFF_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffType'}
                options={tariffCoaMappingState.dynamicOptions.tariffTypeDropdownOptions}
                maxLength={60}
                requiredFieldList={tariffCoaMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffCoaMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_COA_MAPPING_CONSTANT.TARIFF_TYPE, currentEditRow?.tariffType, isAdd, isEdit, tariffCoaMappingState.dynamicOptions.tariffTypeDropdownOptions, tariffCoaMappingVM])

    const memoRaType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={false}
                readOnlyValue={currentEditRow?.raType || ''}
                fieldValue={currentEditRow?.raType}
                fieldLabel={TARIFF_COA_MAPPING_CONSTANT.RA_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'raType'}
                options={tariffCoaMappingState.dynamicOptions.raTypeDropdownOptions}
                maxLength={60}
                requiredFieldList={tariffCoaMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffCoaMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_COA_MAPPING_CONSTANT.RA_TYPE, currentEditRow?.raType, isAdd, isEdit, tariffCoaMappingState.dynamicOptions.raTypeDropdownOptions, tariffCoaMappingVM])

    const memoTariffCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.tariffCode || ''}
                fieldValue={currentEditRow?.tariffCode}
                fieldLabel={TARIFF_COA_MAPPING_CONSTANT.TARIFF_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffCode'}
                options={memoTariffCodeOptions}
                maxLength={60}
                requiredFieldList={tariffCoaMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffCoaMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_COA_MAPPING_CONSTANT.TARIFF_CODE, currentEditRow?.tariffCode, isAdd, isEdit, memoTariffCodeOptions, tariffCoaMappingVM])

    const memoRevenueAccountCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.revenueAccountCode || ''}
                fieldValue={currentEditRow?.revenueAccountCode}
                fieldLabel={TARIFF_COA_MAPPING_CONSTANT.REVENUE_AC_CODE}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'revenueAccountCode'}
                maxLength={60}
                isTextFieldCapitalize={true}
                requiredFieldList={tariffCoaMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffCoaMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_COA_MAPPING_CONSTANT.REVENUE_AC_CODE, currentEditRow?.revenueAccountCode, isAdd, isEdit, tariffCoaMappingVM])

    const memoCostCenterCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.costCenterCode || ''}
                fieldValue={currentEditRow?.costCenterCode}
                fieldLabel={TARIFF_COA_MAPPING_CONSTANT.COST_CENTER_CODE}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'costCenterCode'}
                maxLength={60}
                isTextFieldCapitalize={true}
                requiredFieldList={tariffCoaMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffCoaMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_COA_MAPPING_CONSTANT.COST_CENTER_CODE, currentEditRow?.costCenterCode, isAdd, isEdit, tariffCoaMappingVM])

    const memoProductServiceCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.productServiceCode || ''}
                fieldValue={currentEditRow?.productServiceCode}
                fieldLabel={TARIFF_COA_MAPPING_CONSTANT.PRODUCT_SERVICE_CODE}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'productServiceCode'}
                maxLength={60}
                isTextFieldCapitalize={true}
                requiredFieldList={tariffCoaMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffCoaMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_COA_MAPPING_CONSTANT.PRODUCT_SERVICE_CODE, currentEditRow?.productServiceCode, isAdd, isEdit, tariffCoaMappingVM])

    const memoSalesChannelCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.salesChannelCode || ''}
                fieldValue={currentEditRow?.salesChannelCode}
                fieldLabel={TARIFF_COA_MAPPING_CONSTANT.SALES_CHANNEL_CODE}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'salesChannelCode'}
                maxLength={60}
                isTextFieldCapitalize={true}
                requiredFieldList={tariffCoaMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffCoaMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_COA_MAPPING_CONSTANT.SALES_CHANNEL_CODE, currentEditRow?.salesChannelCode, isAdd, isEdit, tariffCoaMappingVM])

    const memoProjectCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.projectCode || ''}
                fieldValue={currentEditRow?.projectCode}
                fieldLabel={TARIFF_COA_MAPPING_CONSTANT.PROJECT_CODE}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'projectCode'}
                maxLength={60}
                isTextFieldCapitalize={true}
                requiredFieldList={tariffCoaMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffCoaMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_COA_MAPPING_CONSTANT.PROJECT_CODE, currentEditRow?.projectCode, isAdd, isEdit, tariffCoaMappingVM])

    const memoSpareCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.spareCode || ''}
                fieldValue={currentEditRow?.spareCode}
                fieldLabel={TARIFF_COA_MAPPING_CONSTANT.SPARE_CODE}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'spareCode'}
                maxLength={60}
                isTextFieldCapitalize={true}
                requiredFieldList={tariffCoaMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffCoaMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_COA_MAPPING_CONSTANT.SPARE_CODE, currentEditRow?.spareCode, isAdd, isEdit, tariffCoaMappingVM])

    const memoActiveInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                fieldKey={"activeInd"}
                requiredFieldList={[]}
                fieldLabel={TARIFF_COA_MAPPING_CONSTANT.ACTIVE_IND}
                fieldType={FieldType.TEXT}
                isReadOnly={true}
                onFieldChange={() => { }} />
            <HPHCheckbox
                label={""}
                checked={currentEditRow?.activeInd === 'Y'}
                disabled={!(isAdd || isEdit)}
                onChange={(e) => tariffCoaMappingVM.onCheckboxChange(e.checked, "activeInd")}
            />
        </div>
        , [TARIFF_COA_MAPPING_CONSTANT.ACTIVE_IND, tariffCoaMappingVM, currentEditRow?.activeInd, isAdd, isEdit])

    const handleEdit = useCallback(() => {
        tariffCoaMappingVM.onEditClick();
    }, [tariffCoaMappingVM]);

    const handleReset = useCallback(() => {
        tariffCoaMappingVM.onResetClick(isAdd);
    }, [tariffCoaMappingVM, isAdd]);

    return <>
        <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>
            <Sidebarheader style={{ width: '100%' }}>
                {isLoading && <Loader Indicator="Spinner" size="Medium" />}
                <Breadcrumb>
                    <HPHBreadcrumb breadcrumbData={[{ title: TariffCoaMappingConstant.Title.TITLE },
                    { title: TariffCoaMappingConstant.Title.TITLE }]} onCurrentClick={() => { }}>
                    </HPHBreadcrumb>
                </Breadcrumb>
                <StyledAction>
                    <>
                        {!(isAdd || isEdit) && <IconButton fileName='Icon-pen' size='medium' disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Edit'} onClick={handleEdit} />}
                        {(isAdd || isEdit) && <IconButton fileName="Icon-reset" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={() => handleReset()} />}
                        <IconButton fileName="Icon-cross" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement='bottom' toolTipText={'Close'} onClick={() => handClose()} />
                        {(isAdd || isEdit) && <IconButton fileName="Icon-tick" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={() => handleSave()} />}
                    </>
                </StyledAction>
            </Sidebarheader>

            <div className={'add-edit-form'} style={{ height: 'calc(100vh - 125px)', maxHeight: 'calc(100vh - 125px)', overflow: 'auto' }}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <div className={'im-flex-row'}>
                            <div className={'flex-row-item-stretch'}>
                                <SidebarTitle>
                                    {isAdd && "New Tariff/COA Setting"}
                                    {!isAdd && (currentEditRow.tariffType + "/" + currentEditRow.tariffCode)}
                                </SidebarTitle>
                                {<SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>}
                            </div>
                        </div>
                        <br />
                        <HPHGroupTitle titleText={TariffCoaMappingConstant.Title.MAPPING_FROM} />
                        <CriteriaItemContainer>
                            {memoTariffType}
                            {memoTariffCode}
                            {memoRaType}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoRevenueAccountCode}
                            {memoCostCenterCode}
                            {memoProductServiceCode}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoSalesChannelCode}
                            {memoProjectCode}
                            {memoSpareCode}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoActiveInd}
                        </CriteriaItemContainer>
                    </div>
                </div>
            </div>
        </StyledSidebar>
    </>
}

export default memo(TariffCoaMappingEditPanel);
