
export const TariffCoaMappingConstant = {
    Table: {
        TARIFF_TYPE: "Tariff Type",
        TARIFF_CODE: "Tariff Code",
        RA_TYPE: "RA Type",
        REVENUE_AC_CODE: "Revenue A/C Code",
        COST_CENTER_CODE: "Cost Center Code",
        PRODUCT_SERVICE_CODE: "Product Service Code",
        SALES_CHANNEL_CODE: "Sales Channel Code",
        PROJECT_CODE: "Project Code",
        SPARE_CODE: "Spare Code",
        ACTIVE_IND: "Active Ind.",
    },
    Title: {
        TITLE: "Tariff/COA Setting",
        MAPPING_FROM: "From",
        MAPPING_TO: "To",
    },
}
export const tariffCoaMappingRequiredFieldList: string[] = [
    'tariffType',
    'tariffCode',
    'raType',
    'revenueAccountCode',
    'costCenterCode',
    'productServiceCode',
    'salesChannelCode',
    'projectCode',
    'spareCode',
    'activeInd',
];
