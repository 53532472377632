import { EMPTY_TARIFF_COA_MAPPING_ENTITY, TariffCoaMappingEntity } from "domain/entity/TariffCoaMapping/TariffCoaMappingEntity";
import { ParameterDetailRepository } from "domain/repository/parameter/ParameterDetailRepo";
import { TariffCoaMappingRepository } from "domain/repository/TariffCoaMapping/TariffCoaMappingRepo";
import { StandardTariffCodeRepository } from "domain/repository/TariffCode/StandardTariffCodeRepo";
import _ from "lodash";
import { DropdownProps } from "presentation/model/DropdownProps";
import { TariffCoaMappingModel } from "presentation/model/TariffCoaMapping/TariffCoaMappingModel";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";

interface TariffCoaMappingVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<TariffCoaMappingModel>> | ((value: SetStateAction<TariffCoaMappingModel>) => void),
    ],
    tariffCoaMappingRepo:TariffCoaMappingRepository,
    parameterDetailRepo: ParameterDetailRepository,
    standardTariffCodeRepo: StandardTariffCodeRepository,
}

export const TariffCoaMappingVM = ({dispatch, tariffCoaMappingRepo,parameterDetailRepo,standardTariffCodeRepo
}:TariffCoaMappingVMProps) => {
    const [tariffCoaMappingDispatch] = dispatch;

    const loadDropdownOption = async() => {

        await standardTariffCodeRepo.getAllStandardTariffCodes().then(
            tariffs => {
                
                let newTariffs = _.orderBy(tariffs, ["tariffType","tariffCode"]);                
                let tariffTypeDropdownOptions:DropdownProps[] = [];
                let tariffCodeDropdownOptions:{[key:string]: DropdownProps[]} = {};
                let allTariffCodeeDropdownOptions:DropdownProps[] = [];

                newTariffs.forEach(tariff => {
                    const isTariffTypeExisted = tariffTypeDropdownOptions.find(t => 
                        t.value === tariff.tariffType);
                    if(!isTariffTypeExisted){
                        tariffTypeDropdownOptions.push({
                            dropdownLabel: tariff.tariffType,
                            tagLabel: tariff.tariffType,
                            value: tariff.tariffType,
                        })
                    }

                    const isTariffCodeExisted = allTariffCodeeDropdownOptions.find(t => 
                        t.value === tariff.tariffCode);

                    if(!isTariffCodeExisted){
                        allTariffCodeeDropdownOptions.push({
                            dropdownLabel: tariff.tariffCode,
                            tagLabel: tariff.tariffCode,
                            value: tariff.tariffCode,
                        })
                    }

                    if(tariff.tariffCode){
                        if(!tariffCodeDropdownOptions[tariff.tariffType]){
                            tariffCodeDropdownOptions[tariff.tariffType] = [];
                        }
                        tariffCodeDropdownOptions[tariff.tariffType].push({
                            dropdownLabel: tariff.tariffCode,
                            tagLabel: tariff.tariffCode,
                            value: tariff.tariffCode,
                        })

                    }
                })            

                tariffCoaMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        tariffTypeDropdownOptions: tariffTypeDropdownOptions,
                        tariffCodeDropdownOptions: tariffCodeDropdownOptions,
                    }
                }))
            }
        )

        await parameterDetailRepo.getAllParameterDtlsByParameterCode("RA_TYPE").then(
            raTypeList => {
                const raTypeDropdownOption = raTypeList?.map((reType) => ({
                    dropdownLabel: reType.parameterDtlCode,
                    tagLabel: reType.parameterDtlCode,
                    value: reType.parameterDtlCode,
                })) ?? []

                tariffCoaMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        raTypeDropdownOptions: raTypeDropdownOption
                    }
                }))
            }
        )

    }

    const searchAllTariffCoaMappingList = async() => {
        tariffCoaMappingDispatch(prevState => {
            return {
                ...prevState,
                currentRow: {...EMPTY_TARIFF_COA_MAPPING_ENTITY},
                currentEditRow: {...EMPTY_TARIFF_COA_MAPPING_ENTITY},
                isShowEditPanel: false,
                isAdd: false,
                isEdit: false,
                updatedRows:[],
                tariffCoaMappingList: [],
            }
        })
        tariffCoaMappingRepo.searchAllTariffCoaMappingList().then(data =>{
            if(data){
                tariffCoaMappingDispatch(prevState => {
                    return {
                        ...prevState,
                        tariffCoaMappingList: data,
                    }
                })
            }else{
                tariffCoaMappingDispatch(prevState => {
                    return {
                        ...prevState,
                        tariffCoaMappingList: [],
                    }
                })
            }
        })
    }

    const updateSelectedRows = async (allRows: TariffCoaMappingEntity[],updatedRows:TariffCoaMappingEntity[]) => {

        tariffCoaMappingDispatch(prevState => {
            return {
                ...prevState,
                tariffCoaMappingList: [...allRows],
                selectedRows: updatedRows,
            }
        })
    }

    const onHeaderFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;

        if(fieldKey === 'chargeType'){
            tariffCoaMappingDispatch(prevState => {
                return {
                    ...prevState,
                    currentEditRow: {
                        ...prevState.currentEditRow,
                        subChargeType: val===prevState.currentEditRow.chargeType?prevState.currentEditRow.subChargeType:''
                    },
                }
            })
        }
        tariffCoaMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: {
                    ...prevState.currentEditRow,
                    [fieldKey]: val,
                },
            }
        })
    }

    const onRowDoubleClick = async (entity: TariffCoaMappingEntity) => {
        tariffCoaMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: entity,
                currentRow: entity,
                isShowEditPanel: true,
            }
        })
    }

    const onCloseEidtPanel = async () => {
        tariffCoaMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: {...EMPTY_TARIFF_COA_MAPPING_ENTITY},
                isShowEditPanel: false,
                isAdd: false,
                isEdit: false,
            }
        })
    }

    const onSave = async (currentRow: TariffCoaMappingEntity) => {
        return tariffCoaMappingRepo.onSave(currentRow);
    }

    const onAddClick = async () => {
        tariffCoaMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: {...EMPTY_TARIFF_COA_MAPPING_ENTITY},
                isAdd: true,
                isShowEditPanel: true,
            }
        })
    }

    const onCheckboxChange = (checked: boolean, fieldName: string) => { 
        tariffCoaMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: {
                    ...prevState.currentEditRow,
                    [fieldName]: checked?"Y":"N",
                },
            }
        })
    } 

    const onResetClick = async (isAdd:boolean) => {
        tariffCoaMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: isAdd?{...EMPTY_TARIFF_COA_MAPPING_ENTITY}:prevState.currentRow,
            }
        })
    }
    const onEditClick = async () => {
        tariffCoaMappingDispatch(prevState => {
            return {
                ...prevState,
                isEdit: true,
            }
        })
    }

    return {
        onEditClick: onEditClick,
        onResetClick: onResetClick,
        onCheckboxChange: onCheckboxChange,
        onAddClick: onAddClick,
        onSave: onSave,
        onCloseEidtPanel: onCloseEidtPanel,
        onRowDoubleClick: onRowDoubleClick,
        onHeaderFieldChange: onHeaderFieldChange,
        updateSelectedRows: updateSelectedRows,
        loadDropdownOption: loadDropdownOption,
        searchAllTariffCoaMappingList: searchAllTariffCoaMappingList,
    }
}