import { ParameterDetailRepoImpl } from "domain/repository/parameter/ParameterDetailRepoImpl";
import { TariffCoaMappingRepoImpl } from "domain/repository/TariffCoaMapping/TariffCoaMappingRepoImpl";
import { StandardTariffCodeRepoImpl } from "domain/repository/TariffCode/StandardTariffCodeRepoImpl";
import { useTariffCoaMappingTracked } from "presentation/store/TariffCoaMapping/TariffCoaMappingProvider";
import { TariffCoaMappingVM } from "presentation/viewModel/TariffCoaMapping/TariffCoaMappingVM";
import { useMemo } from "react";

export const useTariffCoaMappingVM = () => {
    const [, setTariffCoaMappingState] = useTariffCoaMappingTracked();
    const tariffCoaMappingVM = useMemo(() =>
        TariffCoaMappingVM({
            dispatch: [setTariffCoaMappingState],
            tariffCoaMappingRepo: TariffCoaMappingRepoImpl(),
            standardTariffCodeRepo: StandardTariffCodeRepoImpl(),
            parameterDetailRepo: ParameterDetailRepoImpl(),
        }), [setTariffCoaMappingState])

    return tariffCoaMappingVM
}